/* Markdown */

li::marker {
    color: #acacac;
}

li > p {
    margin-bottom: 0.25em !important;
}

pre {
    margin-bottom: 1em;
}

/* Syntax Highligher */
.syntax-highlighter::-webkit-scrollbar {
    display: none;
}
